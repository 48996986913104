<template>
  <div class="lawknowledge">
      <div class="volunteer-continar">
        <div class="lawtitle">
        <span>政策法规</span>
       </div>
       <div class="lawcontent">
           <Newslist 
           :list="lawknowlist" 
           :nodename="'xxpt_flzc'"
           @childByValue="childByValue"></Newslist>
       </div>
    </div> 
  </div>
</template>

<script>
import Newslist from '@/components/newslist'
import apiUrls from '@api'
export default {
  components : {
    Newslist
  },
  name: 'Lawknowledge',
  data () {
    return {
      lawknowlist: []
    }
  },
  created () {
    this.getlawknow()
  },
  methods : {
    getlawknow () {
      apiUrls.getlearnplat({node: 'xxpt_flzc'}).then(res=>{
      this.lawknowlist= res.results.data
      })
    },
    childByValue (childByValue) {
      this.lawknowlist = childByValue
    }
  }
}
</script>
<style lang="less" scoped>
.lawknowledge {
 background-color: #f5f5f5;
.volunteer-continar{
    width: 1200px;
    min-height: 400px;
    padding-bottom: 40px;
    margin: 0 auto;
    background-color: #f5f5f5;
    &::before {
        display: block;
        content: ".";
        clear: both;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }
        .lawtitle  {
        margin-top: 10px;
        font-size: 23px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
            &::before {
            content: "";
            display: block;
            height: 20px;
            width: 20px;
            background: url('../../../assets/img/title.png') no-repeat center center;
        }
        }
    }

    }
    .lawcontent {
    min-height: 571px;
    background-color: #ffffff;  
    box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
    padding: 39px 63px 47px 40px;
    }
}
}
</style>
